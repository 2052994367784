import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ServiceEditModal.module.scss';
import { Field, Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { salonManagementActions } from '../../../../store';
import TextField from '../../../../../Common/TextField';
import * as yup from 'yup';
import ButtonSelector from '../../../../../Common/CustomSelector/ButtonSelector';
import clsx from 'clsx';
import useWindowSize from '../../../../../../utils/useWindowSize';
import { getWorkers } from '../../../../store/selectors';

const servicesTimesInMinutes = [
  {
    value: 15,
    name: '15 minuti',
  },
  {
    value: 20,
    name: '20 minuti',
  },
  {
    value: 25,
    name: '25 minuti',
  },
  {
    value: 30,
    name: '30 minuti',
  },
  {
    value: 35,
    name: '35 minuti',
  },
  {
    value: 40,
    name: '40 minuti',
  },
  {
    value: 45,
    name: '45 minuti',
  },
  {
    value: 50,
    name: '50 minuti',
  },
  {
    value: 55,
    name: '55 minuti',
  },
  {
    value: 60,
    name: '60 minuti',
  },
];

export default ({ closeModal, service, isEditForm }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const workers = useSelector(getWorkers());
  useEffect(() => {
    dispatch(salonManagementActions.GET_WORKERS.REQUEST());
    return () => {
      dispatch(salonManagementActions.GET_WORKERS.CLEAR());
    };
  }, []);
  const validationSchema = {
    name: yup.string('Inserisci un nome').required('Il Nome è obbligatorio'),
    cost: yup
      .number('Inserisci un prezzo')
      .min(4, 'Deve essere uguale o superiore a 4')
      .required('Il Prezzo è obbligatorio'),
  };

  const initialValues = {
    id: isEditForm ? service.id : '',
    name: isEditForm ? service.name : '',
    duration: isEditForm ? service.duration : 5,
    cost: isEditForm ? service.cost : '',
    description: isEditForm ? service.description : '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={(values) => {
        const req = {
          idServizio: values.id,
          nome: values.name,
          time: values.duration,
          prezzo: values.cost,
          descrizione: values.description,
          setToAll: isEditForm ? true : false,
        };
        if (isEditForm) {
          return dispatch(salonManagementActions.EDIT_SERVICE.REQUEST(req, closeModal));
        }

        delete req.idServizio;
        req.color = '#FFB49D';
        req.onlyVisibile = 0;

        dispatch(
          salonManagementActions.CREATE_SERVICE.REQUEST(req, ({ idservezio }) =>
            dispatch(
              salonManagementActions.EDIT_SERVICE_WORKERS.REQUEST({
                idServizio: idservezio,
                activeWorkersIds: workers.map((worker) => worker.id),
                workersToRemove: [],
              }),
            ),
          ),
        );

        return closeModal();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form
          className={clsx(styles.service_modal_wrapper, {
            [styles.service_modal_wrapper_mobile]: isMobile,
          })}
        >
          <div className={styles.body}>
            <Field
              name={'name'}
              id={'name'}
              label='Nome'
              component={TextField}
              height={'50px'}
              margin={'0px 0px 30px 0px'}
              placeholder={'Campo di testo da compilare'}
            />
            <Field
              name={'description'}
              id={'description'}
              label='Descrizione'
              component={TextField}
              textarea={true}
              height={'120px'}
              margin={'0px 0px 20px 0px'}
              placeholder={'Campo di testo da compilare'}
            />
            <ButtonSelector
              value={values.duration}
              setValue={(value) => setFieldValue('duration', value)}
              options={servicesTimesInMinutes}
              title={'Durata'}
              marginLeft={0}
              height='50px'
              margin='0px 0px 60px 0px'
              width={'100%'}
            />
            <Field
              name={'cost'}
              id={'cost'}
              label='Prezzo'
              component={TextField}
              height={'50px'}
              margin={'30px 0px 30px 0px'}
              placeholder={'Campo di testo da compilare'}
            />
          </div>
          <hr />
          <div className={clsx(styles.actions_container, styles.footer)}>
            <Button className={styles.confirm_button} variant='contained' type={'submit'}>
              Salva
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
