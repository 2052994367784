import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from '../../../../../Clients/store';
import { getClientsShort } from '../../../../../Clients/store/selectors';
import {
  getCalendarWorkers,
  getCalendarWorkerServices,
  getTaskById,
} from '../../../../store/selectors';
import InputSelector from '../../../../../Common/CustomSelector/InputSelector';
import MultiplySelector from '../../../../../Common/CustomSelector/MultiplySelector';
import TextField from '../../../../../Common/TextField';
import { ReactComponent as Calendar } from '../../../../../../assets/svg/icons/calendar_blue.svg';
import { ReactComponent as Scissors } from '../../../../../../assets/svg/icons/scissors_blue.svg';
import { ReactComponent as Person } from '../../../../../../assets/svg/icons/person_blue.svg';
import { ReactComponent as Armchair } from '../../../../../../assets/svg/icons/armchair_blue.svg';
import { ReactComponent as Bin } from '../../../../../../assets/svg/icons/trash_red.svg';
import CustomDateTimePicker from '../../../../../Common/CustomDateTimePicker';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import styles from './TaskModal.module.scss';
import Switcher from '../../../../../Common/Switcher';
import HoveredInfoIcon from '../../../../../Common/HoveredInfoIcon';
import ButtonSelector from '../../../../../Common/CustomSelector/ButtonSelector';
import { Button } from '@mui/material';
import CustomTimePicker from '../../../../../Common/CustomTimePicker';
import clx from 'classnames';
import CustomDatePicker from '../../../../../Common/CustomDatePicker';
import { schedulerActions } from '../../../../store';
import ConfirmDeleteModal from '../../../../../Common/ConfirmDeleteModal';
import useWindowSize from '../../../../../../utils/useWindowSize';
import ConfirmSecondaryModal from '../../../../../Common/ConfirmSecondaryModal';
import constants from 'shared/Loader/store/constants';
import { getSpecifLoader } from 'shared/Loader/store/selectors';
import store from 'store/store';
import ConfirmModal from 'Containers/Common/ConfirmModal';
import { getActiveSalonId } from 'Containers/Auth/store/selectors';
import Spinner from 'Containers/Common/Spinner';

const recurrenceOptions = [
  {
    name: 'Ogni settimana',
    value: 1,
    days: 7,
  },
  {
    name: 'Ogni due settimana',
    value: 2,
    days: 14,
  },
  {
    name: 'Ogni tre settimana',
    value: 3,
    days: 21,
  },
  {
    name: 'Ogni quattro settimana',
    value: 4,
    days: 28,
  },
];

const PartialTask = ({
  taskValue: { day, workerId, task },
  closeModal,
  isEditForm = false,
  isMobile,
  clients,
}) => {
  const dispatch = useDispatch();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationBookingModal, setConfirmationBookingModal] = useState({});
  const salonId = useSelector(getActiveSalonId());
  const [isLoading, setIsLoading] = useState(false);

  const loading = useSelector(getSpecifLoader(constants.CLIENT));
  useEffect(() => {
    dispatch(
      clientActions.GET_CLIENTS.REQUEST({}, () => { }, {
        loader: constants.CLIENT,
      }),
    );
  }, [dispatch]);

  const workers = useSelector(getCalendarWorkers());
  const taskToEdit = isEditForm ? useSelector(getTaskById(task.idprenotazione)) : {};

  const initialValues = {
    worker_id: workerId || '',
    client_id: isEditForm ? taskToEdit.utente : '',
    service_ids:
      isEditForm && taskToEdit?.servizi?.length
        ? taskToEdit.servizi.map((task) => task.idServizio)
        : [],
    date: task?.unixStartTime ? dayjs(task.unixStartTime * 1000) : dayjs(),
    startDate: task?.unixStartTime ? dayjs(task.unixStartTime * 1000) : dayjs(),
    endDate: task?.unixEndTime ? dayjs(task.unixEndTime * 1000) : null,
    recurring_booking: false,
    recurrence: 1,
    recurrenceEndDate: task?.unixStartTime
      ? dayjs(task.unixStartTime * 1000).add(1, 'month')
      : null,
    services: useSelector(getCalendarWorkerServices()),
    description: isEditForm ? taskToEdit.nota : '',
  };
  const validationSchema = isEditForm
    ? {
      worker_id: yup.string().required('Seleziona una poltrona'),
      startDate: yup.date().required('Data obbligatoria'),
      endDate: yup.date().required('Data obbligatoria'),
    }
    : {
      worker_id: yup.string().required('Seleziona una poltrona'),
      client_id: yup
        .number()
        .when('description', ([description], schema) =>
          !description
            ? schema.required('Selezione il cliente o inserisci una nota con il suo nome')
            : schema.nullable(true),
        ),
      service_ids: yup
        .array()
        .min(1, 'seleziona almeno un servizio')
        .required('Seleziona almeno un servizio'),
      startDate: yup.date().required('Data obbligatoria'),
      endDate: yup.date().required('Data obbligatoria'),
      description: yup.string(),
      recurring_booking: yup.boolean(),
      recurrence: yup
        .number()
        .when('recurring_booking', ([recurring_booking], schema) =>
          recurring_booking ? schema.required('Campo obbligatorio') : schema.nullable(true),
        ),
      recurrenceEndDate: yup
        .date()
        .when('recurring_booking', ([recurring_booking], schema) =>
          recurring_booking ? schema.required('Campo obbligatorio') : schema.nullable(true),
        ),
    };
  const removeBooking = (recurrence) => {
    dispatch(
      schedulerActions.DELETE_CALENDAR_TASK.REQUEST({
        deleteAllRicorrenti: recurrence,
        idPoltrona: taskToEdit.poltrona,
        idPrenotazione: taskToEdit.idprenotazione,
        ricorrente: taskToEdit.ricorrente,
      }),
    );
    closeModal();
  };
  const [sendNotifica, setSendNotifica] = useState(false);
  const [confirmNotificationModal, setConfirmNotificationModal] = useState({});
  const BoxNotice = ({ sendNotifica, setSendNotifica = () => { } }) => {
    return (
      <div className={styles.notice}>
        Invia una notifica al cliente &nbsp;
        <input
          className={styles.boxNotice}
          checked={sendNotifica}
          onChange={(e) => {
            e.preventDefault();
            setSendNotifica(e.target.checked);
          }}
          type='checkbox'
        />
      </div>
    );
  };

  const errCallback = (values) => {
    setConfirmationBookingModal(values);
    setIsLoading(false);
  };

  const loadingAfterCall = () => {
    setIsLoading(false);
    closeModal();
  }


  const editOrCreateBooking = (values) => {
    setIsLoading(true);
    if (isEditForm) {
      const formedTask = {
        idPrenotazione: taskToEdit.idprenotazione,
        orario_start: values.startDate.format('HH:mm') + ':00',
        orario_end: values.endDate.format('HH:mm') + ':00',
        data_prenotazione: values.startDate.format('YYYY-MM-DD'),
        poltrona: values.worker_id,
        nota: values.description,
        sendNotificaUpdateUser: sendNotifica ?? false,
      };
      dispatch(schedulerActions.EDIT_CALENDAR_TASK.REQUEST(formedTask, () => setTimeout(loadingAfterCall, 1000)));
    } else {
      const services = values.services
        .filter(
          (service) =>
            +service.worker_id === +values.worker_id && values.service_ids.includes(service.value),
        )
        .map((service) => ({ idservezio: service.value, nome: service.name }));
      const formedValues = {
        idPoltrona: values.worker_id,
        idUtente: values.client_id,
        servizi: services,
        oraInizio: values.startDate.format('HH:mm') + ':00',
        oraFine: values.endDate.format('HH:mm') + ':00',
        dataPrenotazione: (isMobile ? initialValues.date : values.startDate).format('YYYY-MM-DD'),
        nota: values.description,
        recurrence: {
          enabled: values.recurring_booking,
          to: values.recurring_booking && values.recurrenceEndDate.format('YYYY-MM-DD'),
          recurrenceType: values.recurrence,
        },
        saveAnyway: values.saveAnyway ?? false,
      };
      dispatch(schedulerActions.ADD_CALENDAR_TASK.REQUEST(formedValues, () => setTimeout(loadingAfterCall(), 1000), {values: values, errCallback: (values)=>errCallback(values)}));
    }
  };
  return (
    <>
      {isLoading && <Spinner />}
      <ConfirmSecondaryModal
        isModalOpen={confirmationModalIsOpen}
        setIsModalOpen={setConfirmationModalIsOpen}
        title={'Disdici prenotazione'}
        subtitle={
          task?.ricorrente
            ? `Questa è una prenotazione ricorrente.
          Vuoi disdire solo quest'appuntamento o anche tutti gli altri 
          ricorrenti di questa prenotazione?
        `
            : 'Sei sicuro di voler disdire la prenotazione?'
        }
        buttons={
          task?.ricorrente
            ? ['Disdici solo questa', 'Disdici tutte le ricorrenti']
            : ['Disdici prenotazione']
        }
        buttonsActions={[() => removeBooking(false), () => removeBooking(true)]}
      />
      <ConfirmModal /** confirm when there are conflicts */
        isModalOpen={Object.values(confirmationBookingModal).length}
        setIsModalOpen={() => setConfirmationBookingModal({})}
        title={
          (isEditForm
            ? 'Sei sicuro che vuoi modificare la prenotazione?'
            : 'Sei sicuro che vuoi creare la prenotazione?') +
          "Sarà sovrapposta ad un'altra già presente"
        }
        content={
          isEditForm && taskToEdit.utente != salonId ? (
            <BoxNotice sendNotifica={sendNotifica} setSendNotifica={setSendNotifica} />
          ) : null
        }
        action={() => editOrCreateBooking(confirmationBookingModal)}
      />
      <ConfirmModal /** confirm notification only */
        isModalOpen={Object.values(confirmNotificationModal).length}
        setIsModalOpen={() => setConfirmNotificationModal({})}
        title={'Sei sicuro che vuoi modificare la prenotazione ?'}
        content={
          isEditForm && taskToEdit.utente != salonId ? (
            <BoxNotice sendNotifica={sendNotifica} setSendNotifica={setSendNotifica} />
          ) : null
        }
        action={() => editOrCreateBooking(confirmNotificationModal)}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={(values) => {
          const schedules = store.getState().schedulerReducer.calendar.appointments;
          const skippedDays = recurrenceOptions.find(
            (option) => option.value === values.recurrence,
          ).days;
          const possibleDays = [dayjs(values.startDate).format('YYYY-MM-DD')];
          const endDate = dayjs(values.recurrenceEndDate).unix();
          let day = dayjs(values.startDate).add(skippedDays, 'days');
          if (values.recurring_booking)
            while (day.unix() <= endDate) {
              possibleDays.push(day.format('YYYY-MM-DD'));
              day = day.add(skippedDays, 'days');
            }

          const hasConflite = Object.entries(schedules).some(
            ([day, schedule]) =>
              possibleDays.includes(day) &&
              schedule.some((x) => {
                if (
                  x.poltrona !== values.worker_id ||
                  x.idprenotazione === taskToEdit.idprenotazione
                )
                  return false;
                const start = dayjs(x.orario_start, 'HH:mm:ss');
                const end = dayjs(x.orario_end, 'HH:mm:ss');
                if (
                  start.unix() < dayjs(values.endDate.format(`HH:mm:ss`), 'HH:mm:ss').unix() &&
                  end.unix() > dayjs(values.startDate.format(`HH:mm:ss`), 'HH:mm:ss').unix()
                )
                  return true;
                return false;
              }),
          );
          if (hasConflite) {
            const value2 = {
              ...values,
              saveAnyway: true
            };
            setConfirmationBookingModal(value2);
          }
          else isEditForm ? setConfirmNotificationModal(values) : editOrCreateBooking(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched }) => (
          <Form
            className={clx(styles.create_task_form, {
              [styles.create_task_form_mobile]: isMobile,
            })}
          >
            <div className={styles.body}>
              <div className={styles.inputs_wrapper}>
                {!isMobile && (
                  <div className={styles.input_wrapper}>
                    <InputSelector
                      options={workers}
                      value={values.worker_id}
                      setValue={(id) => {
                        setFieldValue('worker_id', id);
                        setFieldTouched('worker_id', false);
                        if (id !== values.worker_id && !isEditForm) {
                          setFieldValue('service_ids', []);
                          setFieldTouched('service_ids', false);
                        }
                      }}
                      placeholder={'Seleziona poltrona'}
                      title={'Poltrona'}
                      icon={<Armchair />}
                      withIcon={true}
                      error={touched.worker_id && errors.worker_id}
                      isEmptyMessage={'Non ci sono poltrone'}
                    />
                  </div>
                )}
                <div className={styles.input_wrapper}>
                  <MultiplySelector
                    readOnly={true}
                    closeOnClick={true}
                    options={values.services.filter(
                      (service) => +service.worker_id === +values.worker_id,
                    )}
                    value={values.service_ids}
                    setValue={(ids) => {
                      const activeServicesDuration = values.services
                        .filter(
                          (service) =>
                            +service.worker_id === +values.worker_id && ids.includes(service.value),
                        )
                        .reduce((acc, item) => acc + item.duration, 0);
                      const newEndTime = values.startDate.add(
                        activeServicesDuration || 30,
                        'minutes',
                      );
                      setFieldValue('service_ids', ids);
                      setFieldValue('endDate', newEndTime);
                      setFieldTouched('service_ids', false);
                      setFieldTouched('endDate', false);
                    }}
                    placeholder={'Seleziona il servizio'}
                    title={'Servizio'}
                    icon={<Scissors />}
                    withIcon={true}
                    disabled={isEditForm}
                    error={touched.service_ids && errors.service_ids}
                    isEmptyMessage={'Non ci sono servizi'}
                  />
                </div>
              </div>
              <div className={styles.time_and_client}>
                <div className={styles.inputs_wrapper}>
                  {!isMobile && (
                    <div className={styles.input_wrapper}>
                      <CustomDateTimePicker
                        value={values.startDate}
                        setValue={(date) => {
                          const activeServicesDuration = values.services
                            .filter(
                              (service) =>
                                +service.worker_id === +values.worker_id &&
                                values.service_ids.includes(service.value),
                            )
                            .reduce((acc, item) => acc + item.duration, 0);
                          const newEndTime = date.add(activeServicesDuration || 30, 'minutes');
                          setFieldValue('recurrenceEndDate', date.add(1, 'month'));
                          setFieldValue('endDate', newEndTime);
                          setFieldValue('startDate', date);
                          setFieldTouched('recurrenceEndDate', false);
                          setFieldTouched('endDate', false);
                          setFieldTouched('startDate', false);
                        }}
                        placeholder={'Select date'}
                        title={'Data e orario'}
                        icon={<Calendar />}
                        withIcon={true}
                        error={touched.startDate && errors.startDate}
                      />
                    </div>
                  )}
                  <div className={styles.input_wrapper}>
                    {!!isMobile && (
                      <CustomTimePicker
                        value={values.startDate}
                        setValue={(date) => {
                          setFieldValue('startDate', date);
                          setFieldTouched('startDate', false);
                          if (
                            dayjs(date).get('hour') * 60 + dayjs(date).get('minute') >
                            dayjs(values.endDate).get('hour') * 60 +
                            dayjs(values.endDate).get('minute')
                          ) {
                            setFieldValue('endDate', values.startDate.add(5, 'minutes'));
                          }
                        }}
                        placeholder={'Select date'}
                        title={'Orario inizio'}
                        icon={<Calendar />}
                        withIcon={true}
                        error={touched.startDate && errors.startDate}
                      />
                    )}
                    <CustomTimePicker
                      value={values.endDate}
                      setValue={(date) => {
                        setFieldValue('endDate', date);
                        setFieldTouched('endDate', false);
                      }}
                      placeholder={'Select date'}
                      title={'Orario fine'}
                      icon={<Calendar />}
                      withIcon={true}
                      minTime={values.startDate.add(5, 'minutes')}
                      error={touched.endDate && errors.endDate}
                    />
                  </div>
                </div>

                <div className={styles.inputs_wrapper}>
                  <div className={styles.input_wrapper}>
                    <InputSelector
                      loading={loading}
                      options={clients}
                      newValue={values.nota}
                      acceptNewValue={isMobile}
                      value={values.client_id}
                      setNewValue={(value) => {
                        setFieldValue('description', value);
                        setFieldTouched('client_id', false);
                      }}
                      setValue={(id) => {
                        setFieldValue('client_id', id);
                        setFieldTouched('client_id', false);
                      }}
                      placeholder={'Seleziona il cliente'}
                      title={isMobile ? 'Cliente o Nota' : 'Cliente'}
                      icon={<Person />}
                      withIcon={true}
                      disabled={isEditForm}
                      error={touched.client_id && errors.client_id}
                      isEmptyMessage={'Non ci sono clienti'}
                      number={true}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.inputs_wrapper}>
                {!isMobile && (
                  <Field
                    name='description'
                    id='description'
                    label='Nota'
                    placeholder='Inserisci nota opzionale'
                    component={TextField}
                    type='text'
                    textarea={true}
                    height={'80px'}
                    width={'100%'}
                    margin={'20px 0'}
                  />
                )}
                {!isEditForm && (
                  <>
                    <div className={styles.switcher_wrapper}>
                      <HoveredInfoIcon
                        iconPosition='right'
                        description={`
                      Seleziona questa opzione se vuoi che la prenotazione si ripeta ogni settimana
                    `}
                        title={'Prenotazione ricorrente'}
                      />
                      {isMobile ? (
                        <input
                          className={styles.recurrence_checkbox}
                          checked={values.recurring_booking}
                          onChange={(e) => {
                            setFieldValue('recurring_booking', e.target.checked);
                            setFieldTouched('recurring_booking', false);
                          }}
                          type='checkbox'
                        />
                      ) : (
                        <div className={styles.switcher_container}>
                          <Switcher
                            value={!values.recurring_booking}
                            setValue={(bool) => setFieldValue('recurring_booking', bool)}
                            isFullWidth={isMobile}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              {!isEditForm && (
                <>
                  <div
                    className={clx(styles.time_inputs_wrapper, {
                      [styles.hide]: !values.recurring_booking,
                    })}
                  >
                    <div className={styles.time_input_wrapper}>
                      <CustomDatePicker
                        value={values.recurrenceEndDate}
                        setValue={(date) => {
                          setFieldValue('recurrenceEndDate', date);
                          setFieldTouched('recurrenceEndDate', false);
                        }}
                        placeholder={'Select date'}
                        title={'Fino al'}
                        icon={<Calendar />}
                        withIcon={true}
                        minDate={values.startDate}
                        maxDate={dayjs(values.startDate).add(1, 'year').toDate()}
                        error={touched.recurrenceEndDate && errors.recurrenceEndDate}
                      />
                    </div>

                    <ButtonSelector
                      options={recurrenceOptions}
                      value={values.recurrence}
                      setValue={(value) => {
                        setFieldValue('recurrence', value);
                        setFieldTouched('recurrence', false);
                      }}
                      width='300px'
                      title={'Ricorrenza'}
                      height='50px'
                      marginLeft={isMobile ? 0 : '20px'}
                    />
                  </div>
                </>
              )}
            </div>
            <hr />
            <div
              className={clx(styles.form_actions, styles.footer, {
                [styles['edit_actions']]: isEditForm,
              })}
            >
              <Button
                onClick={() => setConfirmationModalIsOpen(true)}
                className={styles.delete_button}
              >
                <Bin />
                Disdici
              </Button>
              <div className={styles.confirm_button_block}>
                {!isMobile && (
                  <Button onClick={closeModal} className={styles.return_button}>
                    Annulla
                  </Button>
                )}
                <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                  Conferma
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PartialTask;
