import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/chiamate.svg';
import styles from './TaskInfoModal.module.scss';
import { Button } from '@mui/material';
import { schedulerActions } from '../../store';
import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import { modalActions } from '../../../Modals/store';
import dayjs from 'dayjs';
import { getTaskById, infoRiccorrenzaSelect } from '../../store/selectors';
import useWindowSize from '../../../../utils/useWindowSize';
import clsx from 'clsx';
import IconButton from '../../../Common/Buttons/IconButton';
import { badgetList, selectBadges } from '../../constants';
import { Badgets } from '../../../Common/Badgets';
import { ReactComponent as Person } from '../../../../assets/svg/icons/profilo.svg';
import { ReactComponent as BadIcon } from 'assets/svg/icons/warning_user.svg';

import ConfirmSecondaryModal from '../../../Common/ConfirmSecondaryModal';
import { ReactComponent as Verified } from '../../../../assets/svg/verified.svg';

const TaskInfoModal = ({ taskValue: { day, workerId, task, isBadClient, searchParams }, closeModal }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationRemoveRecurrenceModalIsOpen, setConfirmationRemoveRecurrenceModalIsOpen] =
    useState(false);
  const taskToEdit = useSelector(getTaskById(task.idprenotazione));

  const infoRiccorrenza = useSelector(infoRiccorrenzaSelect());

  const removeBooking = (recurrence) => {
    dispatch(
      schedulerActions.DELETE_CALENDAR_TASK.REQUEST({
        deleteAllRicorrenti: recurrence,
        idPoltrona: taskToEdit.poltrona,
        idPrenotazione: taskToEdit.idprenotazione,
        ricorrente: taskToEdit.ricorrente,
      }),
    );
    closeModal();
  };

  useEffect(() => {
    if (task?.ricorrente != null) {
      dispatch(
        schedulerActions.GET_DETAILS_PRENOTAZIONE_RICORRENTE.REQUEST({
          guidRicorrenza: task.ricorrente,
        }),
      );
    }
  }, [task]);


  //funzione burtta per ottenere la data in formato bello
  const convertISOToDate = (ISOString) => {
    const date = new Date(ISOString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getDayTask = (day) => {
    const today = new Date();
    if (dayjs(today).format('YYYY-MM-DD') === day) {
      return 'Oggi';
    } else {
      const date = dayjs(day).format('DD MMM');
      return `${date}`;
    }
  };
  // console.log('taskToEdit', infoRiccorrenza);
  return (
    <>
      <ConfirmSecondaryModal
        isModalOpen={confirmationModalIsOpen}
        setIsModalOpen={setConfirmationModalIsOpen}
        title={'Disdici prenotazione'}
        subtitle={
          task?.ricorrente
            ? `Questa è una prenotazione ricorrente.
          Vuoi disdire solo quest'appuntamento o anche tutti gli altri 
          ricorrenti di questa prenotazione?
        `
            : 'Sei sicuro di voler disdire la prenotazione?'
        }
        buttons={
          task?.ricorrente
            ? ['Disdici solo questa', 'Disdici tutte le ricorrenti']
            : ['Disdici prenotazione']
        }
        buttonsActions={[() => removeBooking(false), () => removeBooking(true)]}
      />
      <div
        className={clsx(styles.info_task_modal, {
          [styles['info_task_modal_mobile']]: isMobile,
        })}
      >
        
        <div className={styles.time_wrapper}>
          {getDayTask(day.date)} {dayjs(task.unixStartTime * 1000).format('HH:mm')} -{' '}
          {dayjs(task.unixEndTime * 1000).format('HH:mm')}
        </div>
        <div className={styles.special_icons_container}>
          {Object.entries(selectBadges(taskToEdit))?.map(([key, value]) => {
            if (value) {
              return (
                <Badgets
                  icon={badgetList[key]?.icon}
                  text={
                    key == 3
                      ? `${badgetList[key]?.text} ${dayjs(taskToEdit.birthday).format('DD MMM')}`
                      : badgetList[key]?.text
                  }
                  type='Large'
                />
              );
            }
          })}
          {task?.ricorrente && infoRiccorrenza?.fineRicorrenza && (
            <p
              style={{
                fontSize: 13,
                color: '#FF6A88',
                paddingLeft: 5,
              }}
            >
              Questa prenotazione ricorrente termina il{' '}
              {convertISOToDate(infoRiccorrenza?.fineRicorrenza)}
            </p>
          )}
        </div>
        <div className={styles.info_block}>
          <div className={styles.info_title}>Servizi prenotati</div>
          <div className={styles.info_value}>
            {taskToEdit.servizi?.map(({ nome }) => nome).join(' | ')}
          </div>
        </div>
        <div className={styles.info_block}>
          <div className={styles.info_title}>Nota</div>
          <div className={styles.info_value}>{taskToEdit.nota}</div>
        </div>
        {taskToEdit.cellulare && (
          <div className={styles.info_block}>
            <div className={styles.info_title}>Telefono</div>
            <div className={styles.info_value}>
              {taskToEdit.cellulare} {taskToEdit.cellulare_verificato ? <Verified /> : null}
            </div>
          </div>
        )}
        {!!task.isActive && (
           (searchParams?.get('allowEdit') != 1 && !!localStorage.getItem('staff')) ? <></>
          : <div
            role='button'
            onClick={() => {
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'editTaskModal',
                  props: {
                    day,
                    workerId,
                    task,
                  },
                }),
              );
            }}
            className={styles.edit_action}
          >
            Modifica prenotazione &gt;
          </div>
        )}
        <div className={styles.info_block}>
          <div className={styles.info_title}>Prenotazione presa il</div>
          <div className={styles.info_value}>
            {(dayjs(task.createAt).format('DD MMM YYYYY H:mm')).replace('+0100', ' alle')}
            </div>
        </div>

        <div className={styles.client_info_block}>
          <div className={styles.client_info_title}>Contatta il cliente</div>
          <div className={styles.info_cell}>
            <div className={styles.info_icons_container}>
              <IconButton
                svg={<WhatsappIcon />}
                type={'whatsapp'}
                size={'49px'}
                isActive={!!taskToEdit.cellulare && !taskToEdit.isBreak}
                value={taskToEdit?.cellulare}
                margin={'0 10px 0 0'}
              />
              <IconButton
                svg={<PhoneIcon />}
                type={'phone'}
                size={'49px'}
                isActive={!!taskToEdit.cellulare && !taskToEdit.isBreak}
                value={taskToEdit.cellulare}
                margin={'0 10px 0 0'}
              />
              <div style={{position: 'relative'}}>
                {isBadClient && <BadIcon style={{ position: 'absolute', top: '-6px', right: '-4px', width: '20px', height: '20px'}}/>}
                <IconButton
                  svg={<Person />}
                  type='default'
                  size={'49px'}
                  isActive={taskToEdit.utente != taskToEdit.salone && !taskToEdit.isBreak}
                  onClick={() => {
                    dispatch(
                      modalActions.OPEN_MODAL.SUCCESS({
                        type: 'clientModal',
                        props: { client_id: taskToEdit.utente },
                      }),
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {searchParams?.get('allowEdit') && (searchParams?.get('allowEdit') != 1 && !!localStorage.getItem('staff')) ? <></>
        : <Button onClick={() => setConfirmationModalIsOpen(true)} className={styles.delete_button}>
          Disdici prenotazione
        </Button>}
      </div>
    </>
  );
};

export default TaskInfoModal;
